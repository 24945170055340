@import "~src/scss/variables";
@import "~noty/src/noty.scss";
@import "~noty/src/themes/mint.scss";
@import "~noty/src/themes/nest.scss";

/* You can add global styles to this file, and also import other style files */
body {
  margin: 0;
  padding: 0;
}

html {
  font-family: $font;
  font-size: 1rem;
  font-weight: normal;
}

h1,h2,h3,h4,h5,h6 {
  font-weight: 500;
}
